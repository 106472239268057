import React from 'react';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import * as styles from './form-container-w-image.module.css';
import JotformEmbed from 'react-jotform-embed';

import {
    KontentMedia,
    KontentRadioMultiChoice,
    KontentStringValue,
} from '../../../types/KontentProps';
import { getMediaData } from '../Global/DataUtils';
import Jotform from '../Jotform';
type DesktopImagePositionOptions = 'left' | 'right';
type MobileImagePositionOptions = 'top' | 'bottom';

interface Props {
    desktop_image: KontentMedia;
    desktop_image_position?: KontentRadioMultiChoice<DesktopImagePositionOptions>;
    mobile_image: KontentMedia;
    mobile_image_position?: KontentRadioMultiChoice<MobileImagePositionOptions>;
    form_headline?: KontentStringValue;
    form_description?: KontentStringValue;
    form_link: KontentStringValue;
    anchor: KontentStringValue;
}

export const FormContainerWImage: React.FC<Props> = ({
    desktop_image,
    desktop_image_position,
    mobile_image,
    mobile_image_position,
    form_headline,
    form_description,
    form_link,
    anchor,
}) => {
    const breakpoints = useBreakpoint();
    const DesktopImage = getMediaData(desktop_image);
    const MobileImage = getMediaData(mobile_image);
    const DesktopImgPosition =
        desktop_image_position?.value[0]?.codename == 'right';
    const MobileImgPosition =
        mobile_image_position?.value[0]?.codename == 'bottom';
    return (
        <div
            className={`${styles.container} ${
                DesktopImgPosition && breakpoints.l ? styles.imageRight : ''
            } ${MobileImgPosition && !breakpoints.l ? styles.imageDown : ''}`}
        >
            <div className={styles.imageContainer}>
                {!breakpoints.l && MobileImage?.url && (
                    <img
                        src={MobileImage?.url}
                        alt={MobileImage?.description || MobileImage?.name}
                    />
                )}
                {breakpoints.l && DesktopImage?.url && (
                    <img
                        src={DesktopImage?.url}
                        alt={DesktopImage?.description || DesktopImage?.name}
                    />
                )}
            </div>
            <div className={styles.formContainer}>
                <h1 id={anchor.value ? anchor.value : ''}>
                    {form_headline?.value}
                </h1>
                <p>{form_description?.value}</p>
                <div className={styles.jotForm}>
                    <Jotform
                        formUrl={form_link.value}
                        formStyle="embed"
                        display={true}
                    />
                </div>
            </div>
        </div>
    );
};
export default FormContainerWImage;

export const fragmentgp = graphql`
    fragment FormContainerWImage on kontent_item_component___form_container_w__image {
        elements {
            desktop_image {
                ...kontentImage
            }
            desktop_image_position {
                value {
                    codename
                }
            }
            mobile_image_position {
                value {
                    codename
                }
            }
            mobile_image {
                ...kontentImage
            }
            form_headline {
                value
            }
            form_description {
                value
            }
            form_link {
                value
            }
            anchor {
                value
            }
        }
    }
`;
